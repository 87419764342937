import { Col, Container, Row, Divider } from '@geberit/gdds';
import { useEffect, useRef, useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';

// styles
import styles from './form.module.scss';

// types
import type { FieldProps } from '../field';

// components
import { Fieldset } from '../fieldset';
import { Message } from 'components/Message/Message';
import { Headline } from 'components/ContentElementsGdds/headline/headline';

// utils
import { FormContext } from '../form-context';
import { buildFieldsetsMap } from '../utils/build-fieldsets-map';
import { FORM_WITH_CONFIRMATION_MESSAGE } from '../utils/mappings';
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';
import { scrollToPosition } from 'utils/scrollTo';
import { classNameBuilder } from 'utils/classNameBuilder';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useNord } from 'utils/hooks/use-nord';
import { useKolo } from 'utils/hooks/use-kolo';
import { useTwyford } from 'utils/hooks/use-twyford';
import { useIsDesktop } from 'components/App/SizeProvider';
import { usePreviewIdBuilder } from 'utils/preview-id-builder';
import { isEmpty } from 'utils/is-empty';
import { useXy } from 'utils/hooks/use-xy';

export interface NordicsFormProps {
  fields: FieldProps[];
  afterSubmit: () => void;
  sectionId?: string;
  hasInvalidFile: boolean;
  border: string;
  oxomiSubmitted?: boolean;
  title?: string;
  subtitle?: string;
  text?: string;
  oxomiButton?: boolean;
  isModal?: boolean;
}

export function NordicsForm({
  fields,
  afterSubmit,
  sectionId,
  hasInvalidFile = true,
  border = '',
  oxomiSubmitted,
  title,
  subtitle,
  text,
  isModal,
}: Readonly<NordicsFormProps>) {
  const { isSubmitting, isValid, handleSubmit } = useFormikContext<Record<string, string>>();
  const prevIsSubmitting = useRef(isSubmitting);
  const { submitMessages, formType, status, setStatus } = useContext(FormContext);
  const isGDDSDesktop = useIsDesktop({ gdds: true });
  const isKolo = useKolo();
  const isTwyford = useTwyford();
  const isXy = useXy();
  const isNord = useNord() || isXy;
  const previewIdBuilder = usePreviewIdBuilder();
  const hasOwnSuccesMessage = FORM_WITH_CONFIRMATION_MESSAGE.includes(formType);
  const fieldsetGroups = useMemo(() => buildFieldsetsMap(fields), [fields]);
  const isWider = ['leadmodule', 'downloadCenter'].includes(formType);

  useEffect(() => {
    if (prevIsSubmitting.current && !isSubmitting && (!isValid || hasInvalidFile)) {
      const errorNodes = document.querySelectorAll('.form-item--error');
      const firstErrorNode = errorNodes.length > 0 ? errorNodes[0].parentElement : null;
      if (!hasOwnSuccesMessage && firstErrorNode) {
        const headerHeight = isGDDSDesktop ? 138 : 60;
        const yPos =
          firstErrorNode.getBoundingClientRect().top + window.scrollY - headerHeight - 10;
        scrollToPosition(0, yPos);
      } else {
        setStatus({ messageIcon: 'alert', validationError: true });
      }
    }
    prevIsSubmitting.current = isSubmitting;
  }, [hasInvalidFile, hasOwnSuccesMessage, isGDDSDesktop, isSubmitting, isValid, setStatus]);

  useEffect(() => {
    if (!oxomiSubmitted) {
      // remove submit message when new oxomi items are selected
      setStatus({});
    }
  }, [oxomiSubmitted, setStatus]);

  useEffect(() => {
    if (hasOwnSuccesMessage && status?.messageIcon === 'success') {
      if (typeof afterSubmit === 'function') afterSubmit();
    }
  }, [afterSubmit, hasOwnSuccesMessage, status]);

  return (
    <>
      <Container
        className={classNameBuilder(
          styles.gddsGridContainer,
          isKolo || isTwyford ? styles.koloForm : styles.nordicsForm,
        )}
        id={sectionId}
        maxContentWidth={buildSize(gridSizes.gddsFullGrid)}
      >
        <Row>
          {!isWider && <Col size={[0, 0, 2]}></Col>}
          <Col size={[4, 8, 8]}>
            <Headline
              isFlexItem
              title={title}
              subtitle={subtitle}
              tag={Formats.h4}
              titlePreviewId={isModal ? undefined : '#st_title'}
              subtitlePreviewId={isModal ? undefined : '#st_subtitle'}
              text={text}
              textClassName={styles.copyText}
              textPreviewId="#st_content"
            />
          </Col>
          {!isWider && <Col size={[0, 0, 2]}></Col>}
        </Row>
        <Row data-preview-id={previewIdBuilder(isModal ? undefined : '#st_formFields')}>
          {!isWider && <Col size={[0, 0, 2]}></Col>}
          <Col size={isWider ? [4, 8, 12] : [4, 8, 8]}>
            {!isEmpty(status) &&
              status.messageIcon &&
              !hasOwnSuccesMessage &&
              ((
                <Message
                  type={status.messageIcon}
                  closable={false}
                  content={
                    status.messageIcon === 'alert' ? submitMessages.error : submitMessages.success
                  }
                />
              ) as JSX.Element)}
            {!isEmpty(status) &&
            status.messageIcon &&
            hasOwnSuccesMessage &&
            status.messageIcon === 'success' ? (
              <Message
                type={status.messageIcon}
                closable={false}
                content={submitMessages.success}
              />
            ) : (
              <form onSubmit={handleSubmit}>
                {fieldsetGroups.map(({ id, type, legend, colorVariant, fields }, index) => (
                  <Fieldset
                    key={(id ?? '') || `container-${index}`}
                    id={id}
                    type={type}
                    legend={legend}
                    colorVariant={colorVariant}
                    fields={fields}
                    withPreviewId={!isModal}
                  />
                ))}
              </form>
            )}
          </Col>
          {!isWider && <Col size={[0, 0, 2]}></Col>}
        </Row>
      </Container>
      {border !== 'disable-border' && (
        <Container maxContentWidth={buildSize(gridSizes[isNord ? 'gddsFullGrid' : 'full'])}>
          <Row>
            {!isWider && <Col size={[0, 0, 2]}></Col>}
            <Col size={isWider ? [4, 8, 12] : [4, 8, 8]} className={styles.border}>
              <Divider />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
