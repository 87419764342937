// styles
import styles from './video.module.scss';

// components
import { Button, BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES } from 'components/Button/Button';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { useVideoConsentTranslations } from '../hooks/use-consent-translations';
import { useUsercentrics } from '../hooks/use-uc-consent';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useUsercentricsWorkaround } from '../hooks/use-usercentrics-workaround';

interface VideoConsentProps {
  type: 'mi24' | 'youtube';
  inTile?: boolean;
  fullWidthHeight?: boolean;
}
export function VideoConsent({ type, inTile, fullWidthHeight }: Readonly<VideoConsentProps>) {
  const translations = useVideoConsentTranslations(type);
  const uc = useUsercentrics();
  const { reloadIfGdds } = useUsercentricsWorkaround();

  if (!translations) return <p>Video consent translations missing</p>;

  if (!uc) {
    return null;
  }

  return (
    <div className={classNameBuilder(styles.consentContainer, inTile && styles.inTileContainer, fullWidthHeight && styles.fullWidthHeight)}>
      <h3>{translations.headline}</h3>
      <InnerHtml as="div" content={translations.text} />
      <Button
        type={BUTTON_TYPES.DEFAULT}
        color={BUTTON_COLORS.WHITE}
        background={BUTTON_BACKGROUND.PRIMARY}
        onClick={() => {
          uc.enableSetting(translations.type);
          reloadIfGdds();
        }}
      >
        {translations.buttonLabel}
      </Button>
    </div>
  );
}
