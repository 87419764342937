import React, { type PropsWithChildren, type MouseEvent } from 'react';
import ReactDomServer from 'react-dom/server';
import { useSelector } from 'react-redux';

// utils
import { usercentricsSettingsId } from 'utils/selectors/globalsSelectors';
import { useTracking } from 'utils/hooks/useTracking';
import { internalFooterLink } from './trackingActions';

export interface CookieLinkProps {
  className?: string;
  tracking?(e: MouseEvent<HTMLAnchorElement>): void;
}

export function CookieLink({
  className = '',
  tracking,
  children,
}: PropsWithChildren<CookieLinkProps>) {
  const track = useTracking();
  const uc_settingsId = useSelector(usercentricsSettingsId);

  const handleTracking = (e) => {
    if (!tracking) {
      track.trackEvent(
        internalFooterLink(
          'Cookie Link',
          ReactDomServer.renderToString(children as React.ReactElement),
        ),
      );
    } else {
      tracking(e);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    handleTracking(e);

    if (uc_settingsId) {
      UC_UI.showSecondLayer(e);
    }
  };

  return (
    <button
      type="button"
      className={`footer-cookie-link ${className} ${!uc_settingsId && 'ucShow_e7Q5KaN7e'}`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}
