import { useMemo, useEffect } from 'react';
import { Col, Container, Row, AspectRatio, Typo, Divider } from '@geberit/gdds';

// styles
import styles from './press-portal-detail-page.module.scss';

// types
import type { PressPortalDetailsPageProps } from './types/press-portal.types';
import type { Material } from './types/mynewsdesk.types';
import { Formats } from '../headline/headlines.types';

// components
import SocialIcons from './social-icons/social-icons';
import RelatedMediaTiles from './media-tiles/related-media-tiles';
import RelatedPressTiles from './press-tiles/related-press-tiles';
import ContactTiles from './contact-tiles/contact-tiles';
import Tags from '../Tags/tags';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import { Headline } from '../headline/headline';

// utils & hooks
import { useFetchMaterial as useViewMaterial } from './hooks/use-fetch-material';
import { formatDate, useGetMaterialReqConfig } from './utils';
import { useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useLocale } from 'utils/use-locale';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { classNameBuilder } from 'utils/classNameBuilder';

export default function PressPortalDetailPage({
  metaData,
  breadcrumbs,
  isGdds,
}: Readonly<PressPortalDetailsPageProps>) {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const language = useCurrentLanguage();
  const locale = useLocale(language);
  const materialReqConfig = useGetMaterialReqConfig(language);
  const { material } = useViewMaterial<Material>(materialReqConfig);

  const { imageUrl, aspectRatio } = useMemo(() => {
    if (!material) {
      return { imageUrl: undefined, aspectRatio: undefined };
    }

    if (isMobile) return { imageUrl: material.image_small, aspectRatio: '16:9' };
    if (isTablet) return { imageUrl: material.image_medium, aspectRatio: '16:9' };

    return { imageUrl: material.image, aspectRatio: '16:9' };
  }, [material, isMobile, isTablet]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [material]);

  return (
    <>
      <Breadcrumb
        items={breadcrumbs.concat({ breadcrumbDisabled: true, label: material?.header })}
      />
      <section className={styles.pressPortalDetailPage}>
        {material && imageUrl && (
          <Container
            className={classNameBuilder(isMobile && styles.fullWidth)}
            {...(!isGdds && { maxContentWidth: '80rem' })}
          >
            <Row>
              <Col>
                <div className={styles.imageContainer}>
                  <AspectRatio ratio={aspectRatio}>
                    {
                      <img
                        className={styles.heroImageC}
                        src={imageUrl}
                        alt={material.image_caption}
                      />
                    }
                  </AspectRatio>
                </div>
              </Col>
            </Row>
          </Container>
        )}

        {material && (
          <Container maxContentWidth={isGdds ? buildSize(gridSizes.gddsFullGrid) : '80rem'}>
            <Row className={styles.document}>
              <Col size={[0, 0, 2]} />
              <Col size={[4, 8, 8]}>
                <div className={styles.socialIconsWrapper}>
                  <SocialIcons
                    socialNetworks={['facebook', 'instagram', 'youtube', 'service-mail']}
                    metaData={metaData}
                    contentType="page"
                  />
                  <Divider alignment="vertical" />
                  <Typo variant="p1">{formatDate(material, locale)}</Typo>
                </div>
                {material.header && (
                  <Headline isFlexItem title={material.header} tag={Formats.h1} />
                )}
                {material?.summary && (
                  <InnerHtml
                    className={isGdds ? styles.summary : styles.noGddsSummary}
                    as="div"
                    content={material?.summary}
                    isGdds
                  />
                )}
                {material?.body && (
                  <InnerHtml
                    className={isGdds ? styles.copyText : styles.noGddsCopyText}
                    as="div"
                    content={material?.body}
                    isGdds
                  />
                )}
              </Col>
              <Col size={[0, 0, 2]} />
              <Col size={[0, 0, 2]} />
              <Col size={[4, 8, 8]}>
                <Tags tags={material.tags} />
              </Col>
              <Col size={[0, 0, 2]} />
            </Row>
            <div className={classNameBuilder(!isGdds && styles.tilesHeadline)}>
              <RelatedPressTiles materials={material.related_news} language={language} />
              <RelatedMediaTiles mediaList={material.related_materials} language={language} />
              <ContactTiles contacts={material.contact_people} language={language} />
            </div>
          </Container>
        )}
      </section>
    </>
  );
}
