import { useSelector } from 'react-redux';
import Link from 'next/link';

// constants
import { QUEUE_TRACK_VIEW_CART } from 'utils/tracking/track';

// styles
import styles from './shoppingbasket.module.scss';

// components
import { BUTTON_TYPES, Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Translation } from '../Translation/Translation';

// utils
import { webshopBasketPageUrlSelector } from 'utils/selectors/globalsSelectors';
import { shoppingBasketCountSelector } from 'utils/selectors/shoppingBasketSelector';
import { queue } from 'utils/job-queue';

function Notification({ show, handleClose }: { show: boolean; handleClose: () => void }) {
  const shoppingCartCount = useSelector(shoppingBasketCountSelector);
  const webshopBasketPageUrl = useSelector(webshopBasketPageUrlSelector);

  return show ? (
    <div className={styles.notification}>
      <div className={styles.contentWrapper}>
        <Translation id="web20_webshop_added_to_shopping_cart" className={styles.title} />
        <Button
          onClick={handleClose}
          type={BUTTON_TYPES.PLAIN}
          align="right"
          className={styles.close}
          color="#fff"
        >
          <Icon symbol="close" />
        </Button>
      </div>
      <Translation
        id="web20_webshop_basket_subtitle"
        placeholder={{ amount: shoppingCartCount.toString() }}
        className={styles.text}
      />
      <Link
        onClick={() => {
          queue.add(QUEUE_TRACK_VIEW_CART);
        }}
        href={webshopBasketPageUrl ?? ''}
      >
        <Translation id="web20_shoppingcart_label" />
      </Link>
    </div>
  ) : null;
}

export default Notification;
