import { ChangeEvent, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

// components
import ShoppingBasketEntry from '../ShoppingBasket/ShoppingBasketEntry';
import type { BasketItem } from '../ShoppingBasket/reducer.types';
import { SessionContext, State as SessionContextState } from '../Session/SessionContext';

// utils
import { ARTICLE_LIST_ACTIONS } from './contants';
import { shoppingBasketItemsSelector } from 'utils/selectors/shoppingBasketSelector';
import { useTracking } from 'utils/tracking/track';
import { roundValueTwoDigits } from 'utils/round-value';

interface ArticleListContainerProps {
  count: number;
  id: string;
  type: string;
  index: number;
  article?: BasketItem;
}

type ActionDispatchers = {
  addProduct: (
    id: string,
    session: SessionContextState['session'],
    ciam: SessionContextState['ciam'],
  ) => void;
  decreaseProduct: (
    id: string,
    session: SessionContextState['session'],
    ciam: SessionContextState['ciam'],
  ) => void;
  setProduct: (
    id: string,
    count: number,
    session: SessionContextState['session'],
    ciam: SessionContextState['ciam'],
  ) => void;
  deleteProduct: (
    id: string,
    session: SessionContextState['session'],
    ciam: SessionContextState['ciam'],
  ) => void;
};

const ArticleListContainer = ({
  article,
  count,
  id,
  type,
  index,
}: Readonly<ArticleListContainerProps>) => {
  const [countInput, setCountInput] = useState<string>(String(count));
  const {
    state: { session, ciam },
  } = useContext(SessionContext);
  const dispatch = useDispatch();
  useEffect(() => {
    setCountInput(String(count));
  }, [count]);

  const basketItems = useSelector(shoppingBasketItemsSelector);
  const { trackWebshop } = useTracking();

  const actionDispatchers: ActionDispatchers = {
    addProduct: (id, session, ciam) =>
      dispatch(ARTICLE_LIST_ACTIONS[type].addProduct(id, session, ciam)),
    decreaseProduct: (id, session, ciam) =>
      dispatch(ARTICLE_LIST_ACTIONS[type].decreaseProduct(id, session, ciam)),
    setProduct: (id, count, session, ciam) =>
      dispatch(ARTICLE_LIST_ACTIONS[type].setProduct(id, count, session, ciam)),
    deleteProduct: (id, session, ciam) =>
      dispatch(ARTICLE_LIST_ACTIONS[type].deleteProduct(id, session, ciam)),
  };

  const setItem = (e: ChangeEvent<HTMLInputElement>) => {
    const inputNum = e.target.value;
    setCountInput(inputNum);
    let parsedNum = parseInt(inputNum, 10);

    if (parsedNum > 99) parsedNum = 99;
    else if (parsedNum < 1) parsedNum = 1;

    if (!isNaN(parsedNum)) {
      actionDispatchers.setProduct(id, parsedNum, session, ciam);
    }
  };

  const onInputBlur = () => {
    if (countInput !== String(count)) {
      setCountInput(String(count));
    }
  };

  const debouncedIncreaseProduct = useDebouncedCallback(
    () => actionDispatchers.addProduct(id, session, ciam),
    500,
  );
  const debouncedDecreaseProduct = useDebouncedCallback(
    () => actionDispatchers.decreaseProduct(id, session, ciam),
    500,
  );

  const deleteItem = () => {
    const basketProduct = basketItems?.find((entry) => entry.key === id);
    if (basketProduct) {
      const totalValue = basketProduct.quantity * parseFloat(basketProduct.pricePerItem);
      trackWebshop('remove_from_cart', {
        value: roundValueTwoDigits(totalValue),
        items: [
          {
            item_id: basketProduct.sku,
            item_name: basketProduct.description,
            quantity: basketProduct.quantity,
            price: parseFloat(basketProduct.pricePerItem),
          },
        ],
      });
    }
    actionDispatchers.deleteProduct(id, session, ciam);
  };

  return (
    <ShoppingBasketEntry
      article={article}
      count={count}
      countInput={countInput}
      decreaseItem={debouncedDecreaseProduct}
      deleteItem={deleteItem}
      increaseItem={debouncedIncreaseProduct}
      onInputBlur={onInputBlur}
      setItem={setItem}
      index={index}
    />
  );
};

export default ArticleListContainer;
