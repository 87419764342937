import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _sortBy from 'lodash.sortby';
import { Container, Row, Col } from '@geberit/gdds';

// styles
import styles from './dlc-nordics.module.scss';

// types
import type { Download, Order, DownloadCenterContainerNordProps } from '../types';
import type { SelectOption } from '@geberit/gdds/dist/esm/form/Dropdown/types/Dropdown.types';
import { Formats } from 'components/ContentElementsGdds/headline/headlines.types';

// components
import FilterNord from './filter/filter';
import NoResult from '../no-result';
import { Headline } from 'components/ContentElementsGdds/headline/headline';
import { CartNordics } from './cart/cart';
import TilesAreaNord from './tile/tiles-area-nord';
import FilterAreaNord from './filter/filter-area-nord';
import SearchBar from './search-bar';
import SorterAreaNord from './filter/sorter';

// utils
import { translationKeysSelector } from 'utils/selectors/translationSelectors';
import { buildSize, gridSizes } from 'utils/gridSize';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useDateFormat } from 'utils/hooks/use-dateformat';
import { fetchDownloadZip } from 'features/download-center/actions';
import { useSessionState } from 'utils/hooks/use-storage-state';
import { useDownloadCenter } from 'features/download-center/use-downloadcenter';
import { catalogBrandNameSelector } from 'utils/selectors/globalsSelectors';
import { useXy } from 'utils/hooks/use-xy';

const ITEMS_PER_PAGE = 18;

export default function DownloadCenterContainer({
  page: {
    headline,
    subline,
    text,
    preFilters = [],
    disableSearchbar = false,
    disableUserFilters = false,
  },
  contentAreas: { content: downloadForm },
  initialData,
}: Readonly<
  DownloadCenterContainerNordProps & {
    initialData: { pages: any[] };
  }
>) {
  const dispatch = useDispatch();
  const translations = useSelector(translationKeysSelector) as Record<string, string>;
  const [orders, setOrders] = useSessionState<Order[]>('orders', []);
  const [downloads, setDownloads] = useSessionState<Download[]>('downloads', []);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const containerRef = useRef();
  const brandName = useSelector(catalogBrandNameSelector) ?? 'geberit';
  const dateFormat = useDateFormat() || 'yyyy-MM-dd';
  const isXy = useXy();

  const {
    data,
    loadMore: handleLoadMore,
    isLastPage,
    setFilters,
    activeFilters,
    search,
    setSearch: handleSearchSubmit,
    setSorting,
  } = useDownloadCenter(
    {
      prefilterTags: preFilters,
      itemsPerPage: ITEMS_PER_PAGE,
    },
    initialData,
  );

  function updateOrder(product) {
    let newOrders: any[] = [];
    if (orders.some((order) => order.id === product.id)) {
      // delete item from cart if already added to cart
      newOrders = orders.filter((order) => order.id !== product.id);
    } else {
      // add item to cart
      newOrders = orders.concat([product]);
    }
    setOrders(newOrders);
  }

  function updateDownload(product) {
    let newDownloads: any[] = [];
    if (downloads.some((download) => download.id === product.id)) {
      // delete item from cart if already added to cart
      newDownloads = downloads.filter((order) => order.id !== product.id);
    } else {
      // add item to cart
      newDownloads = downloads.concat([product]);
    }
    setDownloads(newDownloads);
  }

  function updateAmount(newOrder) {
    const value = orders.map((order) => (order.id === newOrder.id ? newOrder : order));
    setOrders(value);
  }

  function downloadZip(download) {
    dispatch(fetchDownloadZip(download, setDownloadProgress, brandName));
  }

  function handleSortChange(nextSort) {
    setSorting(nextSort.value);
  }

  function handleFilterChange(filterKey: string, e: SelectOption[]) {
    const filterValue = e.map((el) => el.value);
    setFilters({ ...activeFilters, [filterKey]: filterValue });
  }

  function renderFilters() {
    return (
      data?.aggregations &&
      Object.keys(data.aggregations).map((filterKey) => {
        const activeItems = activeFilters[filterKey] ?? [];
        return (
          <FilterNord
            key={filterKey}
            filterKey={filterKey}
            options={_sortBy(data.aggregations[filterKey], [(o) => o.value.toLowerCase()])}
            handleFilterChange={handleFilterChange}
            activeItems={Array.isArray(activeItems) ? activeItems : [activeItems]}
          />
        );
      })
    );
  }

  const filtersLength = Object.keys(data?.aggregations || {}).length;
  const withSeparatFilters = filtersLength > 1;

  return (
    <Container maxContentWidth={buildSize(gridSizes.gddsFullGrid)}>
      <Row>
        <Col size={[6, 8, 8]}>
          <div className={styles.textbox}>
            <Headline
              isFlexItem
              title={headline}
              subtitle={subline}
              tag={isXy ? Formats.h1 : Formats.h2}
              variant={Formats.h1}
              className={styles.headline}
              text={text}
            />
          </div>
        </Col>
        <Col size={[6, 6, 4]} className={styles.cartColumn}>
          <CartNordics
            orders={orders}
            downloads={downloads}
            updateDownload={updateDownload}
            updateOrder={updateOrder}
            updateAmount={updateAmount}
            downloadZip={downloadZip}
            translations={translations}
            downloadProgress={downloadProgress}
            setDownloadProgress={setDownloadProgress}
            downloadForm={downloadForm}
            getSessionStorage={() => {
              setOrders([]);
            }}
          />
        </Col>
      </Row>
      <Row className={styles.centeredCol}>
        <Col size={withSeparatFilters ? [4, 4, 4] : [4, 6, 6]}>
          <div className="c-downloadcenter">
            <div
              className={classNameBuilder(
                'c-search-navigation',
                withSeparatFilters && 'without-filter',
              )}
            >
              {disableSearchbar || (
                <SearchBar handleSearchSubmit={handleSearchSubmit} initialValue={search} />
              )}
              {!withSeparatFilters && !disableUserFilters && renderFilters()}
            </div>
          </div>
        </Col>
      </Row>
      <>
        {withSeparatFilters && !disableUserFilters && (
          <FilterAreaNord
            data={data}
            activeFilters={activeFilters}
            handleFilterChange={handleFilterChange}
          />
        )}
        <SorterAreaNord totalResults={data?.totalResults} handleSortChange={handleSortChange} />
        <TilesAreaNord
          dateFormat={dateFormat}
          updateOrder={updateOrder}
          updateDownload={updateDownload}
          handleLoadMore={handleLoadMore}
          results={data?.results || []}
          orders={orders}
          downloads={downloads}
          lastPage={isLastPage}
          containerRef={containerRef}
        />
      </>
      {data && data.totalResults === 0 && (
        <NoResult query={search} headline={translations.web20_search_results} />
      )}
    </Container>
  );
}
