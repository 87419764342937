// components
import { CountryForm, type CountryFormProps } from './country-form';
import { NordicsForm, type NordicsFormProps } from './nord/form';

// utils
import { useNord } from 'utils/hooks/use-nord';
import { useXy } from 'utils/hooks/use-xy';

export function FormSwitch(props: Readonly<CountryFormProps & NordicsFormProps>) {
  const isNord = useNord();
  const isXy = useXy();

  if (isNord || isXy) {
    return <NordicsForm {...props} />;
  }

  return <CountryForm {...props} />;
}
