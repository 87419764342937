import { format } from 'date-fns';
import { usePathname, useSearchParams } from 'next/navigation';

// types
import type { Material, Media, MediaType, Resource } from './types/mynewsdesk.types';
import type { MaterialRequestConfig } from './types/press-portal.types';
import { type Locale } from 'date-fns';

// utils
import { generateSlug } from 'utils/slug';

export function sortByDate(a: Material, b: Material): number {
  return getTime(b) - getTime(a);
}

export function formatDate(material: Resource, locale: Locale | undefined): React.ReactNode {
  if (!material?.published_at?.datetime || !locale) {
    return null;
  }
  return format(new Date(material.published_at.datetime), 'dd LLLL uuuu kk:mm', {
    locale,
  });
}

function getTime(material: Material) {
  if (!material?.published_at?.datetime) {
    return 0;
  }

  return new Date(material.published_at.datetime).getTime();
}

export function getMaterialUniqueIdentifier(material: Material | Media): string {
  const slug = generateSlug(material?.header);

  return `${slug}-${material?.type_of_media.slice(0, 2).toUpperCase()}${material?.id}`;
}

export function useGetMaterialReqConfig(locale: string): MaterialRequestConfig {
  const pathname = usePathname();
  const params = useSearchParams();

  const types: Record<string, MediaType> = {
    BL: 'blog_post',
    NE: 'news',
    PR: 'pressrelease',
    IM: 'image',
    VI: 'video',
    DO: 'document',
  };

  let title = pathname;
  if (params.has('title')) {
    title = params.get('title') as string;
  }

  const materialIdentifierMatch = /[A-Z]{2}\d{3,9}$/;
  const regExpMatchArray = title.match(materialIdentifierMatch);

  if (regExpMatchArray) {
    const materialIdentifier = regExpMatchArray[0];

    const materialIdMatchArray = materialIdentifier.match(/\d{3,9}/);
    const id = materialIdMatchArray?.[0];

    const materialTypeMatchArray = materialIdentifier.match(/[A-Z]{2}/);
    const type = materialTypeMatchArray?.[0] as MediaType;

    return {
      id: Number(id),
      type: types[type],
      locale,
    };
  }

  return { id: undefined, type: undefined, locale };
}

export async function downloadMedia(media: Media) {
  const link = document.createElement('a');
  link.style.display = 'none';

  if (media.type_of_media === 'image' || media.type_of_media === 'video') {
    link.setAttribute('download', media.header);
    link.href = media.download_url;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  if (media.type_of_media === 'document') {
    link.setAttribute('download', media.document_name);
    const blob = await fetch(media.document, {
      method: 'GET',
    }).then((resp) => resp.blob());
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }
}

export function encodeUmlaute(str: string) {
  const umlautMap = {
    '\u00dc': 'UE',
    '\u00c4': 'AE',
    '\u00d6': 'OE',
    '\u00fc': 'ue',
    '\u00e4': 'ae',
    '\u00f6': 'oe',
    '\u00df': 'ss',
  };

  return str
    .replace(/[\u00dc\u00c4\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp(`[${Object.keys(umlautMap).join('|')}]`, 'g'), (a) => umlautMap[a]);
}
