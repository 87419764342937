import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter, useSearchParams } from 'next/navigation';
import { AnyAction } from 'redux';

// gdds components
import { Badge, Button, Container, Tooltip } from '@geberit/gdds';

// components
import { MetaNavigationDesktop } from '../metanav/meta-navigation-desktop';
import Logo from 'components/Logo/Logo';
import { Profile } from '../profile/profile';
import { Translation } from 'components/Translation/Translation';
import { LocatorPin } from '../locator-switch/locator-pin';
import { Search } from '../search/search';
import { AnimatedLogo } from 'components/Logo/animated-logo';

// styles
import {
  AnimationWrapperIcons,
  IconContainer,
  IconsContainer,
  IconsWrapper,
  LogoContainer,
  NavbarContainer,
  NavbarInnerContainer,
  SearchBarWrapperMobile,
  SearchWrapper,
} from './navbar.styles';
import { OnlineCatalogButton } from '../common/common.styles';

// constants
import { ANIMATION_DURATION } from '../mega-menu-header';
import { THEME_NAMES } from 'themes/theme.types';

// utils
import { useMetaNavigationInfo } from '../../../../utils/use-metanav';
import { homepageSelector } from 'utils/selectors/homepageListSelectors';
import { useTracking } from 'utils/hooks/useTracking';
import {
  searchTrackingAction,
  siteShortcutTrackingAction,
} from 'components/Navigation/trackingActions';
import {
  useDesktopFlyoutOpen,
  useIsPageScrolled,
  useLocatorPageOpen,
  useMainNavShown,
  useNavOpen,
  useSearchOpen,
} from '../mega-menu-provider';
import { ocbSelector, webshopLandingpageUrlSelector } from 'utils/selectors/globalsSelectors';
import { FocusVisibleManager } from 'utils/focus-visible';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useClientSideRender } from 'utils/hooks/use-client-side-render';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useIsDesktop, useIsMobile } from 'components/App/SizeProvider';
import { LanguageSwitchDesktop } from '../language-switch/language-switch-desktop';
import { shoppingBasketCountSelector } from 'utils/selectors/shoppingBasketSelector';
import { webshopMaintenanceEnabledSelector } from 'utils/selectors/maintenanceSelectors';
import { currentCatalogSelector } from 'utils/selectors/productCatalogSelectors';
import { SessionContext } from 'components/Session/SessionContext';
import { loadArticleCounter } from 'components/ShoppingBasket/actions';
import { useYext } from 'components/Search/use-yext';
import { useThemeName } from 'utils/hooks/use-theme';
import { queue } from 'utils/job-queue';
import { QUEUE_TRACK_VIEW_CART } from 'utils/tracking/track';

export function Navbar() {
  const homepage = useSelector(homepageSelector);
  const { isNavOpen, setIsNavOpen } = useNavOpen();
  const { isDesktopFlyoutOpen, setIsDesktopFlyoutOpen } = useDesktopFlyoutOpen();
  const { isLocatorPageOpen, setIsLocatorPageOpen } = useLocatorPageOpen();
  const onlineCatalogButton = useSelector(ocbSelector);
  const { isSearchOpen, setIsSearchOpen } = useSearchOpen();
  const { isPageScrolled } = useIsPageScrolled();
  const isClient = useClientSideRender();
  const [isIcon, setIsIcon] = useState(true);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const searchWrapperRef = useRef<HTMLDivElement>(null);
  const webshopLandingPageUrl = useSelector(webshopLandingpageUrlSelector);
  const { webshopEnabled, webshopBasketPageUrl } = useMetaNavigationInfo();
  const shoppingCartCount = useSelector(shoppingBasketCountSelector);
  const webShopUrl = shoppingCartCount === 0 ? webshopLandingPageUrl : webshopBasketPageUrl;
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);
  const dispatch = useDispatch();
  const currentCatalog = useSelector(currentCatalogSelector);
  const translate = useTranslationFunction();
  const {
    state: { session, ciam, sessionIsLoading },
  } = useContext(SessionContext);

  const { isMainNavShown } = useMainNavShown();
  const track = useTracking();
  const router = useRouter();
  const searchParams = useSearchParams();
  const ocbURL = translate(onlineCatalogButton?.linkTarget as string);
  const yextUrl = useYext();

  const themeName = useThemeName() ?? THEME_NAMES.DEFAULT;

  let maxContentWidth = '90rem';
  if (themeName === 'DEFAULT') {
    maxContentWidth = '80rem';
  }

  const showButtonOnMobile = !(isSearchOpen || isLocatorPageOpen);
  const showOnlyIcons = isMainNavShown;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    if (showOnlyIcons) {
      timeoutId = setTimeout(() => setIsIcon(showOnlyIcons), ANIMATION_DURATION);
    } else {
      setIsIcon(showOnlyIcons);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showOnlyIcons]);

  useEffect(() => {
    if (!isSearchOpen) return;

    const searchInput = document.querySelector<HTMLInputElement>(
      `.${isDesktop ? 'desktop' : 'mobile'}-search`,
    );

    const scrollHandler = () => {
      searchInput?.blur();
    };

    const focusHandler = () => {
      document.addEventListener('scroll', scrollHandler);
    };

    const blurHandler = () => {
      document.removeEventListener('scroll', scrollHandler);
    };

    searchInput?.addEventListener('focus', focusHandler);
    searchInput?.addEventListener('blur', blurHandler);

    return () => {
      document.removeEventListener('scroll', scrollHandler);
      searchInput?.removeEventListener('focus', focusHandler);
      searchInput?.removeEventListener('blur', blurHandler);
    };
  }, [isSearchOpen, isDesktop]);

  useEffect(() => {
    if (currentCatalog && !sessionIsLoading && webshopEnabled && !webshopDowntime) {
      dispatch(loadArticleCounter(session, currentCatalog, ciam) as unknown as AnyAction);
    }
  }, [currentCatalog, session, ciam, sessionIsLoading, webshopEnabled, webshopDowntime, dispatch]);

  return (
    <NavbarContainer
      isMainNavShown={isMainNavShown}
      isNavOpen={isNavOpen}
      isDesktopFlyoutOpen={isDesktopFlyoutOpen}
      isPageScrolled={isPageScrolled}
      isSearchOpen={isSearchOpen}
      id="page-header"
    >
      <Container maxContentWidth={maxContentWidth}>
        <NavbarInnerContainer isNavOpen={isNavOpen}>
          <LogoContainer>
            <Logo
              homepage={homepage}
              onClick={() => {
                setIsNavOpen(false);
                setIsDesktopFlyoutOpen(false);
              }}
            />
          </LogoContainer>

          {showOnlyIcons && <LanguageSwitchDesktop />}
          <MetaNavigationDesktop showOnlyIcons={showOnlyIcons} />
          <IconsWrapper showOnlyIcons={showOnlyIcons}>
            <SearchWrapper ref={searchWrapperRef}>
              {!searchParams.has('q') && (
                <IconContainer showOnMobile={showButtonOnMobile}>
                  <Tooltip
                    promptText={translate('mega_menu_tooltip_search')}
                    initialtooltipPosition="bottom"
                    offset={{ bottom: 12 }}
                    onClickedActive={false}
                    onHoveredActive={showOnlyIcons && !isMobile}
                  >
                    <Button
                      isIcon={isIcon}
                      symbol="search"
                      stylingType={showOnlyIcons ? 'icon' : 'flat'}
                      onClick={handleOnClick}
                      aria-label={translate('mega_menu_tooltip_search')}
                    >
                      <AnimationWrapperIcons showOnlyIcons={showOnlyIcons}>
                        <Translation id="mega_menu_search" className="label" />
                      </AnimationWrapperIcons>
                    </Button>
                  </Tooltip>
                </IconContainer>
              )}

              <FocusVisibleManager>
                <SearchBarWrapperMobile show={isSearchOpen}>
                  <Search className="mobile-search" />
                </SearchBarWrapperMobile>
              </FocusVisibleManager>
            </SearchWrapper>
            <LocatorPin showOnlyIcons={showOnlyIcons} isIcon={isIcon} />
            <IconsContainer
              showOnMobile={showButtonOnMobile}
              showOnlyIcons={showOnlyIcons}
              className="icons"
            >
              {webshopEnabled && !webshopDowntime && webShopUrl && (
                <IconContainer
                  className="basket-icon"
                  showOnMobile={Boolean(webshopEnabled && !webshopDowntime && webShopUrl)}
                >
                  <Tooltip
                    promptText={translate('mega_menu_tooltip_basket')}
                    initialtooltipPosition="bottom"
                    offset={{ bottom: 12 }}
                    onClickedActive={false}
                    onHoveredActive={showOnlyIcons && !isMobile}
                  >
                    <Button
                      isIcon={isIcon}
                      symbol="onlineshop"
                      stylingType={showOnlyIcons ? 'icon' : 'flat'}
                      onClick={() => {
                        router.push(webShopUrl ?? '');
                        if (webShopUrl === webshopBasketPageUrl) handleTracking();
                      }}
                      aria-label={translate('mega_menu_tooltip_basket')}
                    >
                      <AnimationWrapperIcons showOnlyIcons={showOnlyIcons}>
                        <Translation className="label" id="mega_menu_shopping_cart" />
                        {shoppingCartCount > 0 && (
                          <div className="cart-counter">
                            <Badge
                              color="warning_info"
                              value={shoppingCartCount}
                              size={showOnlyIcons ? 'standard' : 'big'}
                            />
                          </div>
                        )}
                      </AnimationWrapperIcons>
                    </Button>
                  </Tooltip>
                </IconContainer>
              )}
              <Profile showOnlyIcons={showOnlyIcons} isIcon={isIcon} />
            </IconsContainer>
            {onlineCatalogButton?.visible && (
              <OnlineCatalogButton
                showOnMobile={false}
                stylingType="inverse"
                isInNavbar
                onClick={() => {
                  track.trackEvent(siteShortcutTrackingAction('Online Catalog'));
                  window.open(ocbURL, '_blank');
                }}
              >
                <Translation id={onlineCatalogButton.label} />
              </OnlineCatalogButton>
            )}
            <IconContainer className="close-icon">
              <Button
                isIcon
                symbol={isNavOpen || isSearchOpen || isLocatorPageOpen ? 'close' : 'menu'}
                stylingType="icon"
                className="menu-button"
                onClick={() => {
                  if (isSearchOpen) {
                    setIsSearchOpen(false);
                  } else if (isLocatorPageOpen) {
                    setIsLocatorPageOpen(false);
                    setIsNavOpen(false);
                  } else {
                    setIsNavOpen(!isNavOpen);
                  }
                }}
              >
                {!(isNavOpen || isSearchOpen || isLocatorPageOpen) && shoppingCartCount > 0 && (
                  <div className="menu-info">
                    <Badge color="warning_info" label={''} />
                  </div>
                )}
              </Button>
            </IconContainer>
          </IconsWrapper>
        </NavbarInnerContainer>
        {isClient && !isNavOpen && (
          <AnimatedLogo
            isSearchOpen={isSearchOpen}
            sticky
            className={classNameBuilder('animated-logo', !isMainNavShown && 'show-on-desktop')}
          />
        )}
      </Container>
    </NavbarContainer>
  );

  function handleTracking() {
    queue.add(QUEUE_TRACK_VIEW_CART);
  }

  function handleOnClick() {
    if (yextUrl) {
      router.push(yextUrl);
      return;
    }
    track.trackEvent(searchTrackingAction('Search'));
    if (window.location.href.indexOf('?q=') > -1) {
      document.querySelector<HTMLInputElement>('.c-search-bar__input')?.focus();
    } else if (searchWrapperRef.current) {
      // dummy input to receive the focus first
      const fakeInput = document.createElement('input');
      fakeInput.setAttribute('type', 'text');
      fakeInput.style.position = 'absolute';
      fakeInput.style.opacity = '0';
      fakeInput.style.height = '0';

      searchWrapperRef.current.prepend(fakeInput);

      // focus so that subsequent async/delayed focus will work
      fakeInput.focus();

      setTimeout(() => {
        const searchInput = document.querySelector<HTMLInputElement>(
          `.${isDesktop ? 'desktop' : 'mobile'}-search`,
        );
        // now we can focus our search input
        if (searchInput) searchInput.focus();

        // cleanup
        fakeInput.remove();
      }, 1000);
      setIsSearchOpen(true);
    }
  }
}
