import { useContext } from 'react';
import { useFormikContext } from 'formik';

// styles
import styles from './form.module.scss';

// types
import type { DropdownField } from './fields/dropdown/dropdown';
import type { ToggleButtonField } from './fields/toggle-button/toggle-button';
import type { SubmitFormField } from './fields/submit-form/submit-form';
import type { InputFieldField } from './fields/input-field/input-field';
import type { DateOrTimePickerField } from './fields/date-or-time-picker/date-or-time-picker';
import type { InlineTextField } from './fields/inline-text/inline-text';
import type { PreconDropdownContainerField } from './fields/dropdown/precon-dropdown-container';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { isEmpty } from 'utils/is-empty';
import { getClassName, getPreviewId } from './field.utils';
import { FormContext } from './form-context';
import { FIELD_MAPPING } from './utils/mappings';
import { getCustomFieldType } from './utils/get-custom-field-type';
import { getWrapperElement } from './utils/get-wrapper-element';
import { checkHasValidPrecodition } from './utils/check-valid-precondition';
import { useThemeName } from 'utils/hooks/use-theme';
import { THEME_NAMES } from 'themes';
import { useNord } from 'utils/hooks/use-nord';
import { useXy } from 'utils/hooks/use-xy';

type FieldElementProps = DropdownField &
  ToggleButtonField &
  SubmitFormField &
  InputFieldField &
  DateOrTimePickerField &
  InlineTextField &
  PreconDropdownContainerField;

export type FieldProps = FieldElementProps & {
  size: number | string;
  index?: number;
  id?: string;
  colorVariant?: string;
};

export function Field(field: Readonly<FieldProps>) {
  const { type, fieldType, label, name, size, required, reduceBottomDistanceToNextElement, index } =
    field;

  const { formType, hasOxomiButton, preconditions } = useContext(FormContext);
  const { values } = useFormikContext<Record<string, string>>();
  const isGroup = useThemeName() === THEME_NAMES.GDDS;
  const isXy = useXy();
  const isNord = useNord() || isXy;

  const ColElement = getWrapperElement('col', isGroup);

  if (fieldType === 'form_spacer') {
    return <div data-id="spacer" className={styles.spacer} />;
  }

  // fieldType given by json from CC and type by LiveChat
  const isFileUpload = type === 'file';
  const FieldElement = FIELD_MAPPING[fieldType || type];
  const fieldSize = Number(size) || 12;

  // oxomi form needs class to layout the buttons
  const hasOxomitButtonWrapper = hasOxomiButton && type === 'button' ? ' oxomi-wrapper' : '';

  // check if field has a precondition
  const hasValidPrecondition = checkHasValidPrecodition(preconditions, values, name);

  return (
    FieldElement && (
      <ColElement
        className={getClassName({
          isGroup,
          fieldSize,
          hasValidPrecondition,
          hasOxomitButtonWrapper,
          isFileUpload,
          isNord,
          reduceBottomDistanceToNextElement,
        })}
        data-preview-id={getPreviewId(formType, index)}
        size={isGroup ? [4, 8, fieldSize] : undefined}
      >
        {!isGroup &&
          !isNord &&
          !isXy &&
          !isEmpty(label) &&
          !isFileUpload &&
          typeof label === 'string' &&
          type !== 'dropdown_models' &&
          fieldType !== 'form_field_checkbox_crm' && (
            <InnerHtml as="span" className="h4" content={required ? `${label}*` : label} />
          )}
        {!isEmpty(label) && !isFileUpload && typeof label !== 'string' && <b>{label}</b>}
        <FieldElement
          // coding guideline exception: we pass down generic props since there are too many different fields
          field={field}
          customType={getCustomFieldType(type)}
          hasValidPrecondition={hasValidPrecondition}
        />
      </ColElement>
    )
  );
}
